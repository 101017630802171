function set_innertext_id(id, text, html = false) {
    if (html) {
        document.getElementById(id).innerHTML = text;
    } else {
        document.getElementById(id).innerText = text;
    }
}

function set_innertext_class(class_name, text, html = false) {
    const elements = document.getElementsByClassName(class_name);
    for (let i = 0; i < elements.length; i++) {
        if (html) {
            elements[i].innerHTML = text;
        } else {
            elements[i].innerText = text;
        }
    }
}

function reset_form(form_id) {
    document.getElementById(form_id).reset();
}

const menu_item_contact = document.getElementById("forms/contact");

console.log("menu_item_contact:" + menu_item_contact);
//turn menu button contact in a modal show
if (menu_item_contact) {
    menu_item_contact.childNodes[1].setAttribute('href', '#')
    menu_item_contact.childNodes[1].setAttribute('data-toggle', 'modal')
    menu_item_contact.childNodes[1].setAttribute('data-target', '#modal_sub_login_reg')
}

const menu_item_site_edit = document.getElementById("Admin_tools/site_edit");
if (menu_item_site_edit) {
    menu_item_site_edit.childNodes[1].setAttribute('href', '#')
    menu_item_site_edit.childNodes[1].setAttribute('data-toggle', 'modal')
    menu_item_site_edit.childNodes[1].setAttribute('data-target', '#modal_site_editor')
}

var showForm = true;

if (menu_item_contact) {
    menu_item_contact.onclick = function () {
        console.log("menu_item_contact clicked");
        const currentPanel = "contact";
        $('.nav-tabs a[href="#' + currentPanel + '"]').tab('show');
        set_innertext_class("flash_message", "");
    };
}

const btn_subscribe = document.getElementById("subscribe");
if (btn_subscribe) {
    btn_subscribe.onclick = function () {
        console.log("btn_subscribe clicked");
        var currentPanel = "newsletter_subscribe";
        $('.nav-tabs a[href="#' + currentPanel + '"]').tab('show');
        set_innertext_class("flash_message", "");
    };
}

const btn_login = document.getElementById("btn_login");
if (btn_login) {
    btn_login.onclick = function () {
        console.log("btn_login clicked");
        var currentPanel = "user_login";
        $('.nav-tabs a[href="#' + currentPanel + '"]').tab('show');
        set_innertext_class("flash_message", "");
    };
}

const btn_show_messages = document.getElementById("btn_show_messages");
if (btn_show_messages) {
    btn_show_messages.onclick = function () {
        console.log("btn_show_messages clicked");
        var currentPanel = "show_messages";
        // now fetch the messages page
        fetch_page('messages');
    };
}


const btn_site_editor_save = document.getElementById("btn_site_editor_save");
if (btn_site_editor_save) {
    btn_site_editor_save.onclick = function () {
        console.log("btn_site_editor_save clicked");

        var colors_dict = {}
        for (let i = 0; i < colors.length; i++) {
            colors_dict[colors[i]] = rgb_color[i];
        }
        console.log('colors_dict:', colors_dict)

        var jsonData = JSON.stringify(colors_dict);
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "/data/save_json_data/site_edit/", true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(jsonData);
        xhr.onload = function () {
            if (xhr.status === 200) {
                console.log("Data saved successfully.");
            } else {
                console.log("Error saving data.");
            }
        };

        $('#modal_site_editor').modal('hide');
    };
}

function handleFormSubmit(formId, url) {
    document.getElementById(formId).addEventListener("submit", function (event) {
        event.preventDefault(); // Prevent the default behavior of the form submit event

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        // Define a function to handle the response from the server
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    try {
                        const response = JSON.parse(xhr.responseText);
                        console.log('response:', response);
                        console.log('response.success:', response.success);
                        console.log('response.message:', response.message);

                        console.log('response.data:', response.data);
                        console.log('response.data.form_errors:', response.data.form_errors);
                        const form_errors = response.data.form_errors;
                        const fields = response.data.fields;

                        if (true) {
                            console.log('response:', response);

                            if (response.success) {
                                // Show an alert with the success message
                            } else {
                                // Show an alert with an error message
                            }

                            if (fields) {
                                console.log('fields:', fields);
                                console.log('Object.entries(fields):', Object.entries(fields));
                                console.log('Object.entries(fields).length:', Object.entries(fields).length);
                                for (const [key, value] of Object.entries(fields)) {
                                    console.log('key, value:', key, value);
                                    set_innertext_id(key, value);
                                }
                                set_innertext_class("flash_message", "");
                            }

                            console.log('form_errors:', form_errors);
                            if (form_errors) {
                                console.log('Object.entries(form_errors):', Object.entries(form_errors));
                                for (const [key, value] of Object.entries(form_errors)) {
                                    console.log('key, value:', key, value);
                                    set_innertext_id(key, value, true);
                                }
                            }

                            if (response.message) {
                                const flash_messages = document.getElementsByClassName("flash_message");
                                for (let i = 0; i < flash_messages.length; i++) {
                                    flash_messages[i].innerHTML = response.message;
                                }
                            }
                        }
                    } catch (e) {
                        console.error('Invalid JSON response:', xhr.responseText);
                    }
                } else {
                    console.error('Request failed with status:', xhr.status);
                }
            }
        };


        xhr.send(new URLSearchParams(new FormData(this)).toString());
    });
}

handleFormSubmit("contact_form", "/forms/contact/true");
handleFormSubmit("login_account_popup", "/users/login/true");